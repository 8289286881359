@import '../../styles/customMediaQueries.css';

.root {
}

.container {
  /* Carousel width breaks if we don't set this */
  min-width: 0;
}

/* heroContainer gives the height for SectionHero */
/* Safari has a bug with vw padding inside flexbox. Therefore we need an extra div (container) */
/* If you've lot of content for the hero, multiple lines of text, make sure to adjust min-heights for each media breakpoint accordingly */
.heroContainer {
  display: flex;
  flex-direction: column;
  padding: 0;
}

.hero {
  flex-grow: 1;
  justify-content: flex-end;
  padding: 16px 0 30px;

  @media (--viewportMedium) {
    padding: 83px 0 30px;
  }

  @media (--viewportLarge) {
    justify-content: center;
  }
}

.sections {
  margin: 0;
}

.section {
  display: flex;
  flex-direction: column;
  height: auto;

  background-color: var(--matterColorF6);
  overflow: auto;
  padding: 0;
}

.sectionRoot {
  padding: 15px 0;
}

.paddingTop30 {
  padding-top: 30px;
}
